import React from "react"
import Layout from "../../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import PageHeader from "../../components/PageHeader"
import Footer from "../../components/Footer"
import Seo from "../../components/seo"
import Link from "../../components/Link"
export default function ShareHoldersPage() {
  const pageTitle = "Shareholders"

  return (
    <Layout isInvestor pageTitle={pageTitle}>
      <Seo title={pageTitle} />
      <div className="max-w-3xl mx-auto investor-container">
        <div className="pt-16">
          <PageHeader text={pageTitle} />
        </div>
        <div className="mb-16">
          <table id="shareholders" className="table-auto w-full">
            <thead>
              <tr className="">
                <th>Shareholders</th>
                <th>Total Shares</th>
                <th>Percentage of Shares (%)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Karl Karlsson*</td>
                <td>9 826 924</td>
                <td>50,3%</td>
              </tr>
              <tr>
                <td>Sumar Pharma**</td>
                <td>3 292 308</td>
                <td>16,9%</td>
              </tr>

              <tr>
                <td>Management &amp; Board Members</td>
                <td>365 385</td>
                <td>1,9%</td>
              </tr>
              <tr>
                <td>Others</td>
                <td>6 036 537</td>
                <td>30,9%</td>
              </tr>
              <tr className="bg-gray-200">
                <td>Grand Total</td>
                <td>19 521 154</td>
                <td>100%</td>
              </tr>
            </tbody>
          </table>
          <div className="my-8 text-sm space-y-4">
            <div>*Both private and through company</div>
            <div>
              **Sumar is an Iceland based company controlled by Aztiq which is
              industry-wide known pharma focused investment firm. For more info
              visit{" "}
              <Link to="https://www.linkedin.com/company/aztiq/">
                https://www.linkedin.com/company/aztiq/
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}
